import * as React from 'react';
import { BaseForm } from './../../components/Websites/BaseForm';
import { serviceLocator } from '../../services';
import fieldsUtil from './fields-util';
import {mainFormFields} from './MainFormFields';
import { Breadcumb } from '../../components/Breadcumb';

interface MainFormProps {
	websiteKey: string;
	
}

interface MainFormState {
	prefabs: { name: any; }[];
}

export class MainForm extends React.Component<MainFormProps,any>{

	state: MainFormState = {
		prefabs: []
	}

	public async componentDidMount() {
		await this.setPrefabs();
	}

	async setPrefabs () {
		const service = serviceLocator.getWebsiteService();
		const { websiteKey } = this.props;
		const data = await service.getFormData(websiteKey, 'content') 
		const cates: { value: any; }[] = [];
		data?.blockPrefabs.map((block: { name: any; }) => cates.push({value: block.name}))
		this.setState({prefabs: cates})
		return data;
	}

	buildFields(): Array<any>{

		const { allBackgroundVariantOptions, blocksAccordion, propsField, allButtonVariantOptions, scopedSCSS } = fieldsUtil;

		return [
			{ key: "blog", title: "Blog", type: "nest", groupdata: true, fields: [
				{ key: "urlPrefix", title: "URL Prefix", type: "text-field", default: "blogs", required: true },
				{ key: "prevNext", title: "Prev/Next", type: "nest", groupdata: true, fields: [
					{ key: "enabled", title: "Show Prev/Next buttons?", type: "switch", default: false },
					{ key: "useTitle", title: "Use Blog Title for Prev/Next buttons", type: "switch", default: false },
					{ key: "prevText", title: "Prev Button Text", type: "text-field", limit: 30, showCounter: false },
					{ key: "nextText", title: "Next Button Text", type: "text-field", limit: 30, showCounter: false },
					{ key: "wrapperClass", title: "Wrapper CSS Class", type: "text-field", limit: 60, showCounter: false },
					{ key: "target", type: "select", title: "Buttons Target", options: [{ value: "_self" }, { value: "_blank" }], default: "_self" },
				]},
				{ key: "pagination", title: "Pagination", type: "nest", groupdata: true, fields: [
					{ key: "ItemsPerPage", type: "select", title: "Items Per Page", options: [{ value: 3 }, { value: 5 }, { value: 10 }, { value: 12 }, { value: 25 }, { value: 50 }, { value: 100 }], default: 10 },
				]},
				{ key: "blogHomeConfig", title: "Blog Home Config", type: "nest", groupdata: true, fields: [
					{ key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
						{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true, default: "Latest Blog Posts" },
						{ key: "description", title: "Meta Description", type: "text-field", required: true, limit: 155, showCounter: true, default: "Discover insightful articles and expert advice on various topics." },
						{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
						{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false },
					]},
					{ key: "blocksBeforeContent", title: "Blocks Before Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "blocksAfterContent", title: "Blocks After Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "pageStyle", title: "Page Style", type: "code", language: "scss" },
				]},
				{ key: "categoriesConfig", title: "Categories Config", type: "nest", groupdata: true, fields: [
					{ key: "categories", title:"Categories", type:"accordion", itemTitleKey:"name", fields:[
						{ key: "name", title: "Name", type: "text-field", required: true },
					]},
					{ key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
						{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true, default: "Explore Categories" },
						{ key: "description", title: "Meta Description", type: "text-field", required: true, limit: 155, showCounter: true, default: "Browse content by categories to find articles of interest." },
						{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
						{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false },
					]},
					{ key: "blocksBeforeContent", title: "Blocks Before Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "blocksAfterContent", title: "Blocks After Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "listPageStyle", title: "List Page Style", type: "code", language: "scss" },
					{ key: "showCategories", title: "Show Categories in Blog?", type: "switch", default: false },
				]},
				{ key: "authorsConfig", title: "Authors Config", type: "nest", groupdata: true, fields: [
					{ key: "authors", title:"Authors", type:"accordion", itemTitleKey:"name", fields:[
						{ key: "name", title: "Name", type: "text-field", required: true },
					]},
					{ key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
						{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true, default: "Author Profiles" },
						{ key: "description", title: "Meta Description", type: "text-field", required: true, limit: 155, showCounter: true, default: "Discover detailed profiles of contributors and their published works." },
						{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
						{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false },
					]},
					{ key: "blocksBeforeContent", title: "Blocks Before Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "blocksAfterContent", title: "Blocks After Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "listPageStyle", title: "List Page Style", type: "code", language: "scss" },
					{ key: "showAuthors", title: "Show Authors in Blog?", type: "switch", default: false },
				]},
				{ key: "tagsConfig", title: "Tags Config", type: "nest", groupdata: true, fields: [
					{ key: "tags", title:"Tags", type:"accordion", itemTitleKey:"name", fields:[
						{ key: "name", title: "Name", type: "text-field", required: true },
					]},
					{ key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
						{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true, default: "Popular Tags" },
						{ key: "description", title: "Meta Description", type: "text-field", required: true, limit: 155, showCounter: true, default: "Explore articles by popular tags and trending topics." },
						{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
						{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false },
					]},
					{ key: "blocksBeforeContent", title: "Blocks Before Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "blocksAfterContent", title: "Blocks After Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "listPageStyle", title: "List Page Style", type: "code", language: "scss" },
					{ key: "showTags", title: "Show Tags in Blog?", type: "switch", default: false },
				]},
				{ key: "yearConfig", title: "Year Config", type: "nest", groupdata: true, fields: [
					{ key: "meta", title: "Meta", type: "nest", groupdata: false, fields: [
						{ key: "title", title: "Title", type: "text-field", required: true, limit: 60, showCounter: true, default: "Blogs for Year" },
						{ key: "description", title: "Meta Description", type: "text-field", required: true, limit: 155, showCounter: true, default: "Explore articles discover insightful content across various topics from year." },
						{ key: "ogimage", title: "Meta OG:Image", type: "image-upload", required: false },
						{ key: "noIndex", title: "No Index", type: "switch", required: true, default: false },
					]},
				]},
				{ key: "taxonomyTermPageConfig", title: "Taxonomy Term Page Config", type: "nest", groupdata: true, fields: [
					{ key: "blocksBeforeContent", title: "Blocks Before Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "blocksAfterContent", title: "Blocks After Content", itemTitleKey: ["id", "name", "uiName", "type"], type: "accordion", clearExcept: ["id"], fields: [
						{ key: "blockInclude", type: "include", "include": "blockIncludeWithPrefabFilled" }
					]},
					{ key: "pageStyle", title: "Page Style", type: "code", language: "scss" },
				]},
				{ key: "leftNavigation", title: "Left Navigation (Blog Posts)", type: "nest", groupdata: true, fields: [
					{ key: "showCategories", title: "Show Categories", type: "switch", default: false },
					{ key: "showYearArchive", title: "Show Year Archives", type: "switch", default: false },
					{ key: "showRecentPosts", title: "Show Recent Posts", type: "switch", default: false },
					{ key: "numberOfRecentPosts", title: "Number Of Recent Posts", type: "text-field", required: true, default: 5 },
				]},
				{ key: "leftNavigationSingleBlogPost", title: "Left Navigation (Single Blog Post)", type: "nest", groupdata: true, fields: [
					{ key: "showCategories", title: "Show Categories", type: "switch", default: false },
					{ key: "showYearArchive", title: "Show Year Archives", type: "switch", default: false },
					{ key: "showRecentPosts", title: "Show Recent Posts", type: "switch", default: false },
					{ key: "numberOfRecentPosts", title: "Number Of Recent Posts", type: "text-field", required: true, default: 5 },
				]},
			]},
			{ key: "globalStyle", title: "Global Style", type: "nest", fields: [
				{ key: "globalSCSS", title: "Global SCSS", type: "code", language:"scss" },
				{ key: "customSCSS", title: "Custom SCSS", type: "code", language:"scss" },
				{ key: "primaryColor", title: "Primary Color", type: "color-text-field", required: true },
				{ key: "secondaryColor", title: "Secondary Color", type: "color-text-field", required: true },
				{ key: "successColor", title: "Success Color", type: "color-text-field", required: true },
				{ key: "dangerColor", title: "Danger Color", type: "color-text-field", required: true },
				{ key: "warningColor", title: "Warning Color", type: "color-text-field", required: true },
				{ key: "infoColor", title: "Info Color", type: "color-text-field", required: true },
				{ key: "lightColor", title: "Light Color", type: "color-text-field", required: true },
				{ key: "darkColor", title: "Dark Color", type: "color-text-field", required: true },
				{ key: "actionColor", title: "Action Color", type: "color-text-field", required: true },
				{ key: "linkColor", title: "Link Color", type: "color-text-field", required: true },
				{ key: "linkColorHover", title: "Link Color Hover", type: "color-text-field", required: true },
				{ key: "baseFontSize", title: "Base Font Size", type: "text-field", required: true },
				{ key: "baseFontSizeMobile", title: "Base Font Size Mobile", type: "text-field", required: true },
				{ key: "bodyFont", title: "Body Font", type: "text-field", required: true },
				{ key: "bodyFontSizeMobile", title: "Body Font Size Mobile", default: "15px", type: "text-field", required: true},
				{ key: "headersFontFamily", title: "Headers Font Family", type: "text-field" },
				{ key: "headersFontWeight", title: "Headers Font Weight", type: "text-field" },
				{ key: "h1Font", title: "Header 1 Font", type: "text-field" },
				{ key: "h2Font", title: "Header 2 Font", type: "text-field" },
				{ key: "h3Font", title: "Header 3 Font", type: "text-field" },
				{ key: "h4Font", title: "Header 4 Font", type: "text-field" },
				{ key: "h5Font", title: "Header 5 Font", type: "text-field" },
				{ key: "h6Font", title: "Header 6 Font", type: "text-field" },
				{ key: "breakpoints", title: "Grid Breakpoints", type: "nest", fields: [
					{ key: "smBreakpoint", title:"Breakpoint (sm)", type:"text-field", tip:"Default: 576" },
					{ key: "smMaxWidth", title:"Max Width (sm)", type:"text-field", tip:"Default: 540" },
					{ key: "mdBreakpoint", title:"Breakpoint (md)", type:"text-field", tip:"Default: 768" },
					{ key: "mdMaxWidth", title:"Max Width (md)", type:"text-field", tip:"Default: 720" },
					{ key: "lgBreakpoint", title:"Breakpoint (lg)", type:"text-field", tip:"Default: 992" },
					{ key: "lgMaxWidth", title:"Max Width (lg)", type:"text-field", tip:"Default: 960" },
					{ key: "xlBreakpoint", title:"Breakpoint (xl)", type:"text-field", tip:"Default: 1200" },
					{ key: "xlMaxWidth", title:"Max Width (xl)", type:"text-field", tip:"Default: 1140" },
				] },
			]},
			{ key: "includes", title: "Includes", type: "nest", fields: [
				{ key: "afterOpeningHeadInclude", title: "After Opening Head", type: "code", language:"html", required: false },
				{ key: "beforeClosingHeadInclude", title: "Before Closing Head", type: "code", language:"html", multiline:true, required: false },
				{ key: "afterOpeningBodyInclude", title: "After Opening Body", type: "code", language:"html", multiline:true, required: false },
				{ key: "beforeClosingBodyInclude", title: "Before Closing Body", type: "code", language:"html", multiline:true, required: false },
				{ key: "delayedScripts", title: "Delayed Scripts", type: "accordion", fields:[
					{ key:"script", title:"Script", tip:"URL to a javascript. To load scripts in sequence, separate them by a semicolon.", type: "text-field", required:true },
					{ key:"delay", title:"Delay", tip:"The time to wait (after page load) before loading the script.", required:true, default: "2000", type: "text-field" },
				] }
			]},
			{ key: "config", title: "Config", type: "nest", fields: [
				{ key: "reviewWidgetKey", title: "Review Widget Key", type: "text-field", required: false },
				{ key: "googleAnalytics", title: "Google Analytics", type: "text-field", required: false },
				{ key: "googleTagManager", title: "Google Tag Manager", type: "text-field", required: false },
				{ key: "facebookPixel", title: "Facebook Pixel", type: "text-field", required: false },
				{ key: "behaviorToken", title: "Behavior Token", type: "text-field", required: false },
				{ key: "enableGlobalFooter", title: "Enable Global-Footer", type: "switch", required: false, default: true },
				// { key: "behaviorToken2", title: "Behavior Token 2", type: "text-field", required: false },
			]},
			{ key: "header", title: "Header", type: "extender", nest:true, groupdata:true, selectorKey:"type", fields: [
					{ key: "type", title: "Type", type:"select", default: "default", options:[
						{value: "default", text: "Default"},
						{value: "blocks", text: "Blocks"},
						{value: "generic", text: "Generic"},
						{value: "widget-v2", text:"Widget"},
						{value: "widget", text:"Widget V1 (Deprecated)"}
					]},
				],
				types: [
					{ key: "default", fields: [
						{ key: "logo", title: "Logo", type: "nest", fields: [
							{ key: "src", title: "Source", type: "image-upload", required: true, default:"/img/logo.svg" },
							{ key: "alt", title: "Alternate Text", type: "text-field", required: true },
							{ key: "height", title: "Height", type: "text-field", default: "7", required: true },
						]},
						{ key: "logoIsWide", title: "Wide Logo", type: "switch", default: true, required: true },
						{ key: "businessCard", title: "Business Card", type: "extender", nest:true, groupdata:true, selectorKey:"enabled",
							fields:[
								{ key: "enabled", title: "Enabled", type:"switch", default: false }
							],
							types: [
								{ key: "true", fields: [
									{ key: "picture", title:"Picture", type:"image-upload", default:"/img/sample-pic.jpg" },
									{ key: "props", title:"Properties", type:"accordion", itemTitleKey: "label", fields: propsField },
									{ key: "button", title:"Button", type:"nest", selectorKey:"enabled", fields: [
										{ key: "label", title: "Label", type: "text-field", required: true },
										{ key: "href", title: "Href", type: "text-field", required: true },
										{ key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
									]},
								] }
							],
						},
						{ key: "extraButtons", title: "Buttons", type: "extender", groupdata:true, nest:true, selectorKey:"enabled",
							fields: [
								{ key: "enabled", title: "Enabled", type:"switch", default: false }
							],
							types: [
								{ key: "true", fields: [
									{ key: "visibleOn", title: "Visible On", type: "select", options: [ { text:"Not set", value: "" }, {value: 'all'}, {value: 'desktop'}, { value: 'mobile' }] },
									{ key: "items", title: "Items", type: "accordion", itemTitleKey: "label", fields:[
										{ key: "label", title: "Label", type: "text-field", required: true },
										{ key: "href", title: "Href", type: "text-field", required: true },
										{ key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
									]},
								]},
							]
						},
						{ key: "backgroundColor", title: "Background Color", type: "color-text-field", default: "#152427" },
						{ key: "textColor", title: "Text Color", type: "color-text-field" },
						{ key: "scopedSCSS", title: "Scoped SCSS", type: "text-field", monospace: true, multiline: true },
					]},
					{ key: "blocks", fields: [
						blocksAccordion("blocks", "Blocks"),
					]},
					{ key: "generic", fields: [
						{ key: "content", title: "Content", type: "code", language:"markdown", default:"<div class=\"container\"><h1>Brand</h1></div>" },
						scopedSCSS()
					]},
					{ key: "widget", fields: [
						{ key: "wrapInContainer", title: "Wrap In Container", type: "switch", default: true },
						{ key: "widget-include", type: "include", include: "widgetInclude" },
					]},
					{ key: "widget-v2", fields: [
						{ key: "variant", title: "Variant", type: "select", includeOptions: "allBackgroundVariantOptions" },
						{ key: "container", title: "Container", type: "select", default: "container", options: [{value:"", text:"None"}, {value:"container", text:"Regular"},{value:"container-fluid", text:"Fluid"}] },
						{ key: "widget-include", type: "include", include: "widgetV2Include" },
					]}
				]
			},
			{ key: "menu", title: "Menu", type: "nest", fields: [
				{ key: "backgroundColor", title: "Background Color", type: "color-text-field" },
				{ key: "textColor", title: "Text Color", type: "text-field" },
				{ key: "toggle", title: "Toggle Content", type: "text-field", tip:"The default is a hamburguer menu icon." },
				{ key: "toggleBackgroundColor", title: "Toggle Background Color", type: "color-text-field" },
				{ key: "toggleTextColor", title: "Toggle Text Color", type: "color-text-field" },
				{ key: "collapseBreakpoint", title: "Collapse Breakpoint", type: "select", options: [{value: 'sm'}, {value: 'md'},{ value: 'lg'}], default:'sm' },
				{ key: "sticky", title: "Sticky", type: "switch", default: "true" },
				{ key: "desktopLayout", title: "Desktop Layout", type: "select", options: [{ text:"Not set", value: "" }, { value: 'fill' }, { value: 'justified' }, { value: 'left' }, { value: 'center' }, { value: 'right' }] },
				{ key: "items", title: "Items", type: "accordion", itemTitleKey: "name", fields:[
					{ key: "items-include", type: "include", include: "menuItemInclude" },
				]},
				{ key: "menuFragments", title: "Fragments", type: "accordion", itemTitleKey: "name", fields:[
					{ key: "name", title: "Name", type:"text-field", required: true },
					{ key: "items", title: "Items", type: "accordion", itemTitleKey: "name", fields:[
						{ key: "items-include", type: "include", include: "menuItemInclude" },
					]},
				]}
			]},
			{ key: "actionBar", title: "Action Bar", type: "nest", fields: [
				{ key: "variant", title: "Variant", type: "select", options: allBackgroundVariantOptions },
				{ key: "buttons", title: "Buttons", type: "accordion", itemTitleKey: "label", fields:[
					{ key: "label", title: "Label", type: "text-field", required: true },
					{ key: "href", title: "Href", type: "text-field", required: true },
					{ key: "variant", title: "Variant", type: "select", options: allButtonVariantOptions },
				]},
			]},
			{ key: "widgetPrefabs", title:"Widget Prefabs", type:"accordion", itemTitleKey:"name", fields:[
				{ key: "name", title: "Name", type: "text-field", required: true },
				{ key: "widget-include", type: "include", include: "widgetV2Include" },
			] },
			{ key: "blockPrefabs", title:"Block Prefabs", type:"accordion", itemTitleKey:"name", fields:[
				{ key: "name", title: "Name", type: "text-field", required: true },
				{ key: "blockInclude", type: "include", include: "blockInclude" }
			] },
			{ key: "footer", title: "Footer", type: "extender", groupdata:true, nest:true, selectorKey:"type", fields: [
				{ key: "type", title: "Type", type:"select", default: "default", options:[{value: "default"}, {value:"blocks"}, {value: "generic"}, {value:"widget"}]},
			],
			types: [
				{ key: "default", fields: [
					{ key: "content", title: "Content", type: "code", language:"markdown", default:"© 2019 SomeBrand" },
					{ key: "smallLetters", title: "Small Letters", type: "code", language:"markdown", default:"" },
					{ key: "variant", title: "Variant", type: "select", options: allBackgroundVariantOptions, default:"primary-darker" },
				]},
				{ key: "generic", fields: [
					{ key: "content", title: "Content", type: "code", language:"markdown", default:"<div class=\"container\"><h1>Brand</h1></div>" },
					scopedSCSS()
				]},
				{ key: "widget", fields: [
					{ key: "variant", title: "Variant", type: "select", includeOptions: "allButtonVariantOptions" },
					{ key: "container", title: "Container", type: "select", default: "container", options: [{value:"", text:"None"}, {value:"container", text:"Regular"},{value:"container-fluid", text:"Fluid"}] },
					{ key: "widget-include", type: "include", include: "widgetV2UngroupedInclude" },
				]},
				{ key: "blocks", fields: [
					blocksAccordion("blocks", "Blocks"),
				]}
			]},
			{ key: "cookieNotice", title: "Cookie Notice", type: "nest", fields: [
				{ key: "disabled", title: "Disabled", type:"switch", default: false },
				{ key: "variant", title: "Variant", type: "select", options: allBackgroundVariantOptions },
				{ key: "text", title: "Text", type: "text-field", default: "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it." },
				{ key: "buttonVariant", title: "Button Variant", type: "select", options: allButtonVariantOptions },
				{ key: "buttonText", title: "Button Text", type: "text-field", default:"Dismiss" },
			]},
		];
	}

	render(){
		const service = serviceLocator.getWebsiteService();
		const { includes, getOptions, blockIncludeFactory } = fieldsUtil;

		const blockIncludeWithPrefabFilled = blockIncludeFactory(false, false, this.state.prefabs)
		includes.blockIncludeWithPrefabFilled = blockIncludeWithPrefabFilled

		const { websiteKey } = this.props;
		return <BaseForm
			themeKey="custom-website"
			title={<Breadcumb paperStyle={false} items={[
				{to:`/websites/custom-website/`, label: `Custom Website`},
				{to:`/websites/custom-website/${websiteKey}/`, label: `Website ${this.props.websiteKey}`},
				{ label: `Global`}
			]} />}
			websiteKey={websiteKey}
			plugins={{ getOptions }}
			getFormInitialData={()=> service.getFormData(websiteKey, 'content').then(value => ({ value, includes, fields: this.buildFields() })) }
			saveData={(data: any)=> service.postFormData(websiteKey, 'content', data)}
			buildSite={()=> service.buildWebsite(websiteKey)}
			getNote={() => service.getNote(websiteKey, "form")}
			saveNote={(note: string) => service.setNote(websiteKey, "form", note)}
		/>
	}
}